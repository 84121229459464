
// 获取url中参数（传入参数名即可）
export function getUrlParams(parName) {
  var str = parName.toLowerCase() + "=";
  var gvalue = "";
  var HREF = location.href;
  var upperHREF = location.href.toLowerCase();
  if (upperHREF.indexOf(str) > 0) {
    gvalue = HREF.substring(upperHREF.indexOf(str) + str.length, upperHREF.length);
    if (gvalue.indexOf('&') > 0) gvalue = gvalue.substring(0, gvalue.indexOf('&'));
    if (gvalue.indexOf("#") > 0) gvalue = gvalue.split("#")[0];
    gvalue = decodeURIComponent(gvalue)
  }
  return gvalue;
}

