<template>
  <div class="product_detail_img">
    <div class="brand_info">
      <img v-for="(item, index) in productInfo?.allImage" :key="index" style="max-width: 1200px"
        :src="`/api/ffs/file/${item.id}`" />
    </div>
    <el-divider v-if="(productInfo.productVideos && productInfo.productVideos.length>0 )|| (productInfo.productDrawings && productInfo.productDrawings.length>0)" style="margin-top: 70px" />
    <div v-if="(productInfo.productVideos && productInfo.productVideos.length>0 )|| (productInfo.productDrawings && productInfo.productDrawings.length>0)" class="history">
      <div class="fs-flex fs-flex-between fs-flex-align-center">
        <div class="header_text" style="color: rgba(17, 17, 18, 1)">
          产品帮助
        </div>
        <div class="pagingBox fs-flex">
          <div class="iconStep fs-flex fs-flex-center fs-flex-align-center" style="margin-right: 30px"
            @click="currentSwiper.slidePrev()">
            <el-icon>
              <ArrowLeft />
            </el-icon>
          </div>
          <div class="iconStep fs-flex fs-flex-center fs-flex-align-center" @click="currentSwiper.slideNext()">
            <el-icon>
              <ArrowRight />
            </el-icon>
          </div>
        </div>
      </div>
      <div class="historyBox">
        <div ref="mySwiper" class="swiper-container" id="mySwiperDetailOne">
          <div class="swiper-wrapper">
            <!-- productVideos -->
            <div class="swiper-slide" v-for="(item, i) in productInfo.productVideos" :key="i">
              <div v-if="item.file">
                <div class="video_box fs-flex fs-flex-align-center fs-flex-center" @click="handleClickVideos(item)">
                  <img style="width: unset; max-width: 80%" src="@/assets/icon/productVideos.png" />
                </div>
                <div class="detailYear" @click="handleClickVideos(item)">{{ item.name }}</div>
              </div>
              <div v-else-if="item.videoUrl">
                <div class="video_box fs-flex fs-flex-align-center fs-flex-center"
                  @click="handleProductVideoClick(item.videoUrl)">
                  <img style="width: unset; max-width: 80%" src="@/assets/icon/productVideos.png" />
                </div>
                <div class="detailYear" @click="handleProductVideoClick(item.videoUrl)">{{ item.name }}</div>
              </div>
            </div>
            <!-- productDrawings -->
            <div class="swiper-slide" v-for="(item, i) in productInfo.productDrawings" :key="i">
              <!-- <a v-if="item.file" target="_blank" :href="`/api/ffs/file/${item.file.id}`">
                <div class="video_box fs-flex fs-flex-align-center fs-flex-center">
                  <img style="width: unset; max-width: 80%" src="@/assets/icon/productDrawings.png" />
                </div>
                <div class="detailYear">{{ item.name }}</div>
              </a> -->
              <div class="video_box fs-flex fs-flex-align-center fs-flex-center" @click="handleOpenDrawing(item)">
                <img style="width: unset; max-width: 80%" src="@/assets/icon/productDrawings.png" />
              </div>
              <div class="detailYear" @click="handleOpenDrawing(item)">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-divider v-if="tianmaoUrl !='' || JDUrl !=''" style="margin-top: 50px" />

    <div class="banner_box"  v-if="tianmaoUrl !='' || JDUrl !=''">
      <div class="features_title" style="color: rgba(17, 17, 18, 1)">品牌</div>
      <div class="brand_box">
        <div class="brandImg">
          <img style="width: 100%;height: 100%;" :src="`/api/ffs/file/${brandCover.id}`" />
        </div>
        <div class="brandRight">
          <!-- <div class="brand_img_box">
            <img style="max-width: 148px" :src="`/api/ffs/file/${productBrandInfo?.brandIcon?.id}`" />
          </div> -->
          <div v-if="tianmaoUrl !=''" class="handle_brand footerButtonCenter brandFooter" @mouseover="() => handleButtonOver(1)"
            @mouseleave="() => handleButtonLeave(1)" @click="handleProductClick(tianmaoUrl)">
            <img :src="tmall" alt="" class="dialogButtonImg" style="object-fit: none" />
          </div>

          <div v-if="JDUrl !=''" class="handle_brand brandFooter" @mouseover="() => handleButtonOver(2)"
            @mouseleave="() => handleButtonLeave(2)" @click="handleProductClick(JDUrl)">
            <img class="dialogButtonImg" :src="JD" alt="" style="object-fit: none" />
          </div>
        </div>
      </div>
    </div>

    <el-dialog v-model="produceVideoVisible" :title="selectVideoInfo?.name" width="50%" :before-close="handleClose">
      <div class="video_dialog fs-flex fs-flex-center fs-flex-align-center">
        <video :src="`/api/ffs/file/${selectVideoInfo?.id}`" autoplay controls loop />
      </div>
      <!-- 小屏时展示 -->
      <div class="video_dialog_mini fs-flex fs-flex-center fs-flex-align-center">
        <video width="320" height="240" :src="`/api/ffs/file/${selectVideoInfo?.id}`" autoplay controls loop />
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="produceVideoVisible = false">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";
import { getUrlParams } from "@/utils/index";
import request from "@/utils/request";
import TmallDefault from "../assets/about/brand/tmall.png";
import JDDefault from "../assets/about/brand/JD.png";
import JDActive from "../assets/about/brand/JDActive.png";
import TmallActive from "../assets/about/brand/tmallActive.png";
import { inject } from "vue";

export default {
  name: "product-detail-img",

  setup() {
    const pageIndicator = inject("pageIndicator");
    pageIndicator.currentPageName = "product";
    pageIndicator.lightMode = true; // 导航栏背景适应浅色内容模式；
    pageIndicator.headerTransparent = false; // 导航栏背景全透明；
    pageIndicator.headerGradient = false; // 导航栏背景渐变；

    console.log("inject pageIndicator[product details image] ...", pageIndicator);
  },

  data() {
    return {
      productId: getUrlParams("productId"),
      productInfo: {},

      produceVideoVisible: false,
      selectVideoInfo: {},


      tmall: TmallDefault,
      JD: JDDefault,
      productBrandInfo: {},
      brandCover: {},
      tianmaoUrl: "",
      JDUrl: "",

    };
  },

  methods: {
    // 点击播放产品
    handleClickVideos(item) {
      this.selectVideoInfo = item.file;
      this.produceVideoVisible = true;
    },
    //点击跳转安装视频
    handleProductVideoClick(url) {
      window.open(url, "_blank");
    },
    // 点击产品品牌店铺跳转详情页
    handleProductClick(url) {
      window.open(url, "_blank");
    },
    // 下载说明书

    handleTextDown(item) {
      debugger;
      console.log(item);
    },

    getData() {
      request({
        url: `/api/ui/product/${this.productId}`,
        method: "get",
      })
        .then((res) => {
          if (res.code == 0) {
            this.productInfo = res.data;
            //console.log(this.productInfo)
            if (res.data.productBrandId) {
              this.getProductBrand(res.data.productBrandId);
            }
            // console.log(res.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getProductBrand(id) {
      request({
        url: `/api/ui/productBrand/${id}`,
        method: "get",
      }).then((res) => {
        this.productBrandInfo = res.data;
        this.brandCover = res.data.productBrandImage[0];
        this.storesUrlType();
      });
    },

    // 获取商店链接
    storesUrlType() {
      console.log('brand',this.productBrandInfo)
      this.tianmaoUrl = this.productBrandInfo?.tmall !=null ? this.productBrandInfo?.tmall : '' ;
      this.JDUrl = this.productBrandInfo?.jd != null ? this.productBrandInfo?.jd : '';
    },

    handleButtonOver(index) {
      if (index == 1) {
        this.tmall = TmallActive;
      } else if (index == 2) {
        this.JD = JDActive;
      }
    },
    handleButtonLeave(index) {
      if (index == 1) {
        this.tmall = TmallDefault;
      } else if (index == 2) {
        this.JD = JDDefault;
      }
    },
    //点击打开安装图纸
    handleOpenDrawing(item) {
      var url = encodeURIComponent(window.location.origin + '/api/ffs/file/' + item.file.id);
      window.open('/pdfjs/web/viewer.html?file=' + url);
    },
    //初始化swiper
    initSwiper() {
      // const perView=this.screenWidth>850&&4
      //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
      this.currentSwiper = new Swiper("#mySwiperDetailOne", {
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
        slidesPerView: "auto", //视口展示slide数1
        spaceBetween: 50,
      });
    },
    //销毁swiper
    destroySwiper() {
      try {
        this.currentSwiper.destroy(true, false);
      } catch (e) {
        console.log("删除轮播");
      }
    },
    //更新swiper
    updateSwiper() {
      this.destroySwiper();
      this.$nextTick(() => {
        this.initSwiper();
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.initSwiper();
    }, 1000);
    this.getData();
  },
  unmounted() {
    // 在组件生命周期结束的时候销毁。
  },
};
</script>


<style lang="less" scoped>
.product_detail_img {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  //padding: 0 184px 20px;
  padding: 0 0px 20px;

  .brand_info {
    //padding-top: 76px;
    padding: 76px 184px 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .history {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 40px;
    box-sizing: border-box;
    // background: #ffffff;

    .header_text {
      font-size: 40px;
      /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
      font-weight: 400;
      color: #ffffff;
    }

    .iconStep {
      width: 40px;
      height: 40px;
      border: 1px solid #333336;
      border-radius: 50%;
      color: #333336;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .historyBox {
    width: 100%;
    margin-top: 33px;

    .video_box {
      width: 100%;
      height: 178px;
      background: #00a0e9;
      cursor: pointer;
    }

    .detailYear {
      font-size: 20px;
      /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
      font-weight: 400;
      color: #000000;
      line-height: 30px;
      margin-top: 20px;
      cursor: pointer;

      overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;//（行数）
        -webkit-box-orient: vertical;

      &:hover {
        color: rgba(0, 160, 233, 1);
        text-decoration: underline;
      }
    }
  }

  .swiper-slide {
    width: 320px;
    /*设为固定值*/
  }

  .brand_box {
    margin-top: 15px;
    display: flex;
    width: 100%;

    .brandImg {
      max-width: 900px;
      max-height: 500px;
    }

    // .brandRight {
    //   width: 100%;
    //   max-width: 300px;
    //   background: RGBA(246, 246, 246, 1);

    //   .brand_img_box {
    //     margin: 191px 0 62px 0;
    //     text-align: center;
    //     height: 39px;
    //   }

    //   .handle_brand {
    //     text-align: center;
    //     margin-bottom: 20px;

    //     .buttonText {
    //       font-size: 16px;
    //       /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
    //       font-weight: 400;
    //       text-decoration: underline;
    //       color: #111112;
    //     }
    //   }
    // }
  }
}

.video_dialog_mini {
  display: none;
}

// @media screen and (max-width: 1460px) {
//   .brand_box {
//     flex-direction: column;
//     align-items: center;

//     .brandRight {
//       max-width: 100% !important;
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       height: 100px !important;

//       .brand_img_box {
//         margin: 0 !important;
//       }

//       .handle_brand {
//         margin-bottom: 0 !important;
//       }
//     }
//   }
// }

@media screen and (max-width: 1300px) {
  /deep/.el-dialog {
    width: 80%;
  }
}

@media screen and (max-width: 400px) {
  .buttonText {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 500px) {
  .swiper-slide {
    width: 250px !important;
    /*设为固定值*/
  }
  .brand_info{
    padding: 0px !important;
  }
}

@media screen and (max-width: 860px) {
  .product_detail_img {
    padding: 0 10px !important;
    /*设为固定值*/
  }

  .history {
    margin-top: 50px !important;

    .header_text {
      font-size: 40px !important;
    }

    .iconStep {
      width: 34px !important;
      height: 34px !important;
      font-size: 16px !important;
    }
  }

  /deep/.el-dialog {
    width: 90%;
  }

  .video_dialog {
    display: none;
  }

  .video_dialog_mini {
    display: flex;
  }
}

.banner_box {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 25px;
}

.brand_box {
  margin-top: 71px;
  display: flex;
  width: 100%;

  .brandImg {
    max-width: 900px;
    max-height: 500px;
  }

  .brandRight {
    width: 100%;
    max-width: 300px;
    background: RGBA(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    .brand_img_box {
      text-align: center;
      height: 39px;
    }

    .handle_brand {
      text-align: center;
    }

    .footerButtonCenter {
      margin-top: 0px;
    }
  }
}

.features_title {
  font-size: 40px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 400;
  color: #000000;
  line-height: 90px;
}

.brandFooter {
  display: inline-block;
  width: 156px;
  height: 52px;
  border: 1px solid rgba(250, 250, 250, 0.5);
  background: none;
  border-radius: 26px;
  text-align: center;
  position: relative;
  color: #fff;
}

.brandFooter:hover {
  color: #00a0e9;
  border: 1px solid rgba(0, 160, 233, 0.5);
}

.dialogButtonImg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@media screen and (max-width: 1460px) {
  .brand_box {
    flex-direction: column;
    align-items: center;

    .brandRight {
      max-width: 100% !important;
      display: flex;
      flex-direction: row !important;
      justify-content: space-between;
      align-items: center;
      height: 100px !important;

      .brand_img_box {
        margin: 0 !important;
      }

      .handle_brand {
        margin-bottom: 0 !important;
      }

      .footerButtonCenter {
        margin-top: 0 !important;
      }
    }
  }
}
.swiper-wrapper{
  height: 100% !important;
}
</style>>
